@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.btn-primary {
		@apply px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
	}
	.input-text-label {
		@apply block text-sm font-medium text-gray-700;
	}
	.input-text-form {
		@apply block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
	}
	.alert-span {
		@apply block mb-5 text-sm font-medium text-red-700;
	}
	.form-container {
		@apply px-4 pt-5 pb-16 mx-4 mb-8 bg-white shadow sm:rounded-lg sm:p-6;
	}
	.container {
		@apply flex flex-col justify-center px-4 pt-5 pb-16 m-auto mx-auto mb-8 bg-white shadow sm:rounded-lg sm:p-6;
	}
	.flex-container {
		@apply flex flex-row justify-center w-full h-full mt-2;
	}
	.btn-gray-blue {
		@apply px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl;
	}
	.btn-gray-blue-active {
		@apply px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl;
	}
	.btn-gray-blue-disabled {
		@apply px-3 py-1 mx-1 text-gray-100 bg-gray-400 shadow-lg rounded-xl;
	}
	.btn-dark-gray-blue {
		@apply px-3 py-1 mx-3 mb-2 text-center text-gray-100 bg-gray-900 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl md:mx-0;
	}
}
